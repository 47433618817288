export const CUSTOM_SOURCES = [
  {
    customGroupName: 'OpsKM Search',
    customDataSourceImages: ['assets/images/data-sources/kmops.png', 'assets/images/data-sources/cdocs.png'],
    dataSource: 'OpsKmCustom',
    customDataSources: ['0/Ops KM Assets', '0/CDOCS'],
  },
  {
    customGroupName: 'Contract Search',
    customDataSourceImages: ['assets/images/data-sources/sharepoint.png'],
    dataSource: 'LegalSPO',
    customDataSources: ['1/SharePoint/LCR', '1/SharePoint/ILGDaW', '1/SharePoint/ILGHQContracts'],
  },
  {
    customGroupName: 'PD Search',
    customDataSourceImages: ['assets/images/data-sources/eln.png'],
    dataSource: 'PDSearchCustom',
    customDataSources: ['0/e-notebook (PerkinElmer)', '0/E-WorkBook ELN (IDBSGxP)', '0/E-WorkBook ELN (IDBSNonGxP)'],
  },
  {
    customGroupName: 'RD ELN Search',
    customDataSourceImages: ['assets/images/data-sources/eln.png'],
    dataSource: 'RDSearchCustom',
    customDataSources: ['0/e-notebook (PerkinElmer)', '0/E-WorkBook ELN (IDBSGxP)', '0/E-WorkBook ELN (IDBSNonGxP)'],
  },
];
