import { useEnvironment } from '@amgen/core';
import { AppContext, AssetContext, DocInteractionContext, MatomoContext } from '@amgen/shared';
import classnames from 'classnames';
import React, { useContext } from 'react';

import './title.scss';

import { AssetTitle } from '../../asset-title/asset-title';
import { TableAssetTitle } from '../../asset-title/table-asset-title';

export interface TitleProps {
  className?: string;
  disableRouterLink?: boolean;
  docPosition: number;
  hideExternalLinkIcon?: boolean;
  view?: string;
}

export const Title: React.FC<TitleProps> = ({
  className,
  disableRouterLink,
  docPosition,
  hideExternalLinkIcon,
  view,
}) => {
  const { pageNo, itemsPerPage } = useContext(AppContext);

  const { docSolrAsset } = useContext(AssetContext);
  const { dimensions, trackMultipleCustomDimensions, trackLinkClickEvent } = useContext(MatomoContext);
  const { appName } = useEnvironment();
  const { onDocTitleClick } = useContext(DocInteractionContext);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);

  const trackDocumentTitleClick = (id: string, url?: string) => {
    const assetDimensionsMap: Map<string, string> = new Map();
    if (docSolrAsset.dataAsset?.content_source) {
      assetDimensionsMap.set(dimensions.DATA_SOURCE, docSolrAsset.dataAsset.content_source);
    }
    if (dimensions.DOCUMENT_ID && !abTestingGroup) {
      assetDimensionsMap.set(dimensions.DOCUMENT_ID, id);
    }
    // if (dimensions.AB_DOCUMENT_ID && abTestingGroup) {
    //   assetDimensionsMap.set(dimensions.AB_DOCUMENT_ID, id);
    // }
    assetDimensionsMap.set(dimensions.APP_NAME, appName);
    const absolutePos = docPosition + (pageNo - 1) * itemsPerPage;
    if (dimensions.DOCUMENT_POSITION && !abTestingGroup) {
      assetDimensionsMap.set(dimensions.DOCUMENT_POSITION, absolutePos.toString());
    }

    if (dimensions.AB_DOCUMENT_POSITION && abTestingGroup) {
      assetDimensionsMap.set(dimensions.AB_DOCUMENT_POSITION, absolutePos.toString());
    }
    trackMultipleCustomDimensions(assetDimensionsMap);

    if (url) {
      trackLinkClickEvent(url);
    }

    onDocTitleClick(id);
  };

  return docSolrAsset.dataAsset ? (
    view === 'table-view' ? (
      <TableAssetTitle
        asset={docSolrAsset.dataAsset}
        className={classnames(className ?? 'fs-16 asset-title w-100')}
        onTitleClick={trackDocumentTitleClick}
        disableRouterLink={disableRouterLink}
        hideExternalLinkIcon={hideExternalLinkIcon}
      />
    ) : (
      <AssetTitle
        asset={docSolrAsset.dataAsset}
        className={classnames(className ?? 'asset-title w-75')}
        onTitleClick={trackDocumentTitleClick}
        disableRouterLink={disableRouterLink}
        hideExternalLinkIcon={hideExternalLinkIcon}
      />
    )
  ) : null;
};

export default Title;
