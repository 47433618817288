import { IRoadmapFeatureFragmentCopy } from '@amgen/api';
import { groupBy } from '@amgen/utils';
import { differenceInCalendarDays } from 'date-fns';

import { QUARTERS } from '../config/timeline';

export const MONTH_WIDTH_COUNT = 6;

export const monthsBetween = (start: string, end: string) => {
  return differenceInCalendarDays(new Date(end), new Date(start)) / 30.5;
};

export const getWidth = (sDate: string, eDate: string) => {
  return `${Math.round(((monthsBetween(sDate, eDate) + 1) * (100 / MONTH_WIDTH_COUNT)) / 10) * 10}%`;
};

export const getStartPosition = (sDate: string, limit?: number) => {
  return `${Math.round(monthsBetween(QUARTERS[limit ?? 0].start, sDate)) * (100 / MONTH_WIDTH_COUNT) + 0.1}%`;
};

export const getPercentage = (sDate: string, eDate: string) => {
  const p = Math.round(
    (monthsBetween(new Date().toDateString(), sDate) / Math.round(monthsBetween(eDate, sDate))) * 100
  );
  return p < 0 ? 0 : p > 100 ? 100 : p;
};

export interface IRoadmapFeatureExtended extends IRoadmapFeatureFragmentCopy {
  coordinates: number[];
  quarterNumber: number;
}

export const getCurrentQuarterIndex = () => {
  const today = new Date();
  const quarter = Math.floor((today.getMonth() + 3) / 3);

  const index = QUARTERS.findIndex(element => element.label === `${today.getFullYear()} Q${quarter}`) - 1;

  // const index = Object.values(QUARTERS)[0][Object.values(QUARTERS)[0].length - 1]?.label.split(' ')[1].replace('Q', '');
  return index;
};

export const useGroupedRoadmapFeatures = (features: IRoadmapFeatureFragmentCopy[], l?: number) => {
  const limit = l ?? 0;
  const featuresExtended: IRoadmapFeatureExtended[] = features
    .map(f => ({
      ...f,
      coordinates: [monthsBetween(QUARTERS[limit].start, f.startDate), monthsBetween(QUARTERS[limit].start, f.endDate)],
      quarterNumber: Math.ceil(monthsBetween(QUARTERS[0].start, f.startDate)),
    }))
    .filter(z => {
      const s = Math.ceil(monthsBetween(QUARTERS[limit].start, z.startDate));
      const e = Math.ceil(monthsBetween(QUARTERS[limit].start, z.endDate));
      return (s >= 0 && e <= 6) || (s <= 0 && e > 0);
    });
  return Object.fromEntries(Object.entries(groupBy(featuresExtended, 'quarterNumber')).sort());
};
